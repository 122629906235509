import { Row, Col, Select, Button } from "antd";
import React, { useEffect, useState } from "react";
import Save from "../../components/reusable/buttons/saveButton";
import Search from "../../components/reusable/buttons/searchButton";
import SelectComponent from "../../components/reusable/form/select/select";
import Loader from "../../components/reusable/loader/loader";
import * as api from "../../config/api/api";
import useHTTP from "../../config/http/useHTTP";
import useZone from "../../hooks/useZone";
import { dateConverter } from "../../utils/utilityFunctions/dateConverter";

export default function AssetProfile() {
  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();
  let [loading, setLoading] = useState(false);
  let [zones, setZones, zoneLoading] = useZone([]);
  let [area, setArea] = useState([]);
  let [assets, setAssets] = useState([]);

  let [selectedZone, setSelectedZone] = useState(null);
  let [selectedArea, setSelectedArea] = useState(null);
  let [selectedAsset, setSelectedAsset] = useState(null);

  let [selectedAssetData, setSelectedAssetData] = useState(null);
  let [partnersFeedback, setPartnersFeedback] = useState([]);

  useEffect(() => {
    if (selectedZone) {
      getAreaByZoneId();
    }
  }, [selectedZone]);
  useEffect(() => {
    if (selectedArea) {
      getAssetByAreaId();
    }
  }, [selectedArea]);

  let getAreaByZoneId = async () => {
    setArea([]);
    // setSelectedAssetData(null);
    setSelectedArea(null);
    setLoading(true);
    let res = await GetData({
      api: `${api.GET_ALL_AREA_BY_ZONE}/${selectedZone}`,
    });
    if (res?.data) {
      setArea(res.data);
    }
    setLoading(false);
  };
  let getAssetByAreaId = async () => {
    setAssets([]);
    setSelectedAsset(null);
    setLoading(true);
    let res = await GetData({
      api: `${api.GET_ALL_ASSET_BY_AREA}/${selectedArea}`,
    });
    if (res?.data) {
      setAssets(res.data);
    }
    setLoading(false);
  };
  let searchAsset = async () => {
    setSelectedAssetData(null);
    setPartnersFeedback([]);
    setLoading(true);
    let res = await GetData({
      api: `${api.GET_ASSET_BY_ASSET_ID}/${selectedAsset}`,
    });
    if (res?.data) {
      setSelectedAssetData(res.data);
    }

    await getPartnersFeedback();

    setLoading(false);
  };

  let getPartnersFeedback = async () => {
    setLoading(true);
    let res = await GetData({
      api: `${api.GET_ASSET_FEEDBAKC_BY_PARTNER_BY_ASSET_ID}/${selectedAsset}`,
    });
    if (res?.data) {
      setPartnersFeedback(res.data);
    }
    setLoading(false);
  };

  return (
    <>
      {(loading || zoneLoading) && <Loader />}
      <Row className="card pa-4 ">
        <Col md={4} className="px-2 ">
          <SelectComponent
            label="Zone"
            placeholder="Select Zone"
            items={zones}
            itemText="zoneName"
            itemValue={"id"}
            onChange={(value) => setSelectedZone(value)}
            value={selectedZone}
          />
        </Col>
        <Col md={4} className="px-2">
          <SelectComponent
            label="Area"
            placeholder="Select Area"
            items={area}
            itemText="areaName"
            itemValue={"id"}
            onChange={(value) => setSelectedArea(value)}
            value={selectedArea}
          />
        </Col>
        <Col md={4} className="px-2 ">
          <SelectComponent
            label="Asset"
            placeholder="Select Asset"
            items={assets}
            itemText="assetName"
            itemValue={"id"}
            onChange={(value) => setSelectedAsset(value)}
            value={selectedAsset}
          />
        </Col>
        <Col md={4} className="px-2 align_item_end mb-1">
          <Search onClick={searchAsset} type="primary" />
        </Col>
      </Row>

      {selectedAssetData && (
        <>
          <Row className="card pa-4 mt-2">
            <Col md={24} className="mb-4">
              <div className="text-2xl">Basic Information</div>
            </Col>
            {details.map((e, index) => (
              <Col key={index} md={4} className="mb-1">
                <div className="border-l-4 border-indigo-500 pl-2">
                  <label className="text-md">{e.name}</label>
                  <p className="text-lg font-semibold">
                    {selectedAssetData?.[e.key]}
                  </p>
                </div>
              </Col>
            ))}
          </Row>

          <Row className="card pa-4 mt-2">
            <Col md={24} className="mb-4">
              <div className="text-2xl text-decoration-line: underline">
                Feedbacks
              </div>
            </Col>
            <Col md={12}>
              <Row>
                <div className="text-xl mb-4">Partner's Feedbacks</div>
              </Row>
              <Row>
                {partnersFeedback.map((e, index) => (
                  <Col key={index} md={24} className="mb-4">
                    <div className="text-md font-semibold mb-1">{e.note}</div>
                    <div className="text-xs">
                      {dateConverter(e.createdAt, true)}
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

let details = [
  {
    name: "Name",
    key: "assetName",
  },
  {
    name: "EIIN",
    key: "eiin",
  },
  {
    name: "Area",
    key: "areaName",
  },
  {
    name: "Zone",
    key: "zoneName",
  },
  {
    name: "Partner Name",
    key: "partnerName",
  },
  {
    name: "Partner Phone",
    key: "partnerPhone",
  },
  {
    name: "Asset Type",
    key: "assetType",
  },
  {
    name: "Asset Status",
    key: "assetStatus",
  },
];
