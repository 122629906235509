import React, { useEffect, useState } from "react";
import { GET_ALL_ACTIVE_PARTNER, GET_ALL_PARTNER } from "../config/api/api";
import useHTTP from "../config/http/useHTTP";

export default function usePartner(initValue = [], type = "active") {
  let [activePartners, setActivePartners] = useState(initValue);
  let [loading, setLoading] = useState(false);

  let { GetData } = useHTTP();

  useEffect(() => {
    getData();
  }, []);

  let getData = async () => {
    setLoading(true);
    let res;
    if (type == "active") {
      res = await GetData({ api: GET_ALL_ACTIVE_PARTNER });
    } else if (type == "all") {
      res = await GetData({ api: GET_ALL_PARTNER });
    }
    if (res?.data) {
      setActivePartners(res.data);
    }
    setLoading(false);
  };

  return [activePartners, setActivePartners, loading];
}
