import { Col } from "antd";
import React, { useEffect, useState } from "react";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import Loader from "../../components/reusable/loader/loader";
import DataTable from "../../components/reusable/table/dataTable";
import {
  CREATE_FEEDBACK_FOR_ASSET,
  DELETE_FEEDBACK_FOR_ASSET,
  GET_ALL_FEEDBACK_FOR_ASSET,
  UPDATE_FEEDBACK_FOR_ASSET,
} from "../../config/api/api";
import useHTTP from "../../config/http/useHTTP";
import useAsset from "../../hooks/useAsset";

export default function FeedbackForPartner() {
  let [loading, setLoading] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);
  let [feedbackForAssets, setFeedbackForAssets] = useState([]);
  let [assets, setAssets, assetsLoading] = useAsset([]);
  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  let [formData, setFormData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  let getData = async (obj) => {
    setLoading(true);
    let res = await GetData({ api: GET_ALL_FEEDBACK_FOR_ASSET });
    if (res.data) {
      setFeedbackForAssets(res.data);
    }
    setLoading(false);
  };

  let onSubmitHandler = async (obj) => {
    setLoading(true);

    if (updateMode) {
      // console.log(obj);
      let res = await UpdateData({
        api: `${UPDATE_FEEDBACK_FOR_ASSET}`,
        body: obj,
      });
      if (res) {
        setFeedbackForAssets((prevState) =>
          prevState.map((e) => {
            if (e.id == obj.id) {
              e = { ...obj };
            }
            return e;
          })
        );
        reset();
      }
    } else {
      let res = await PostData({ api: CREATE_FEEDBACK_FOR_ASSET, body: obj });
      if (res) {
        setFeedbackForAssets((prevState) => [...prevState, res.data]);
        reset();
      }
    }

    setLoading(false);
  };
  let onCancelHandler = async () => {
    reset();
  };
  let reset = () => {
    setFormData({});
    setUpdateMode(false);
  };

  let editHandler = async (obj) => {
    setFormData(obj);
    setUpdateMode(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };
  let deleteHandler = async (obj) => {
    if (window.confirm("Do you want to procceed?")) {
      setLoading(true);
      let res = await DeleteData({
        api: `${DELETE_FEEDBACK_FOR_ASSET}/${obj.id}`,
        body: obj,
      });
      if (res) {
        setFeedbackForAssets((prevState) =>
          prevState.filter((e) => e.id != obj.id)
        );
      }
      setLoading(false);
    }
  };

  let formGeneratorData = [
    {
      type: "select",
      label: "Asset",
      key: "assetId",
      md: 24,
      // required: true,
      placeholder: "Select Asset",
      items: assets,
      itemText: "assetName",
      itemValue: "id",
    },
    {
      type: "input",
      fieldType: "textarea",
      label: "Note",
      key: "note",
      md: 24,
      required: true,
      placeholder: "Note",
    },
    // {
    //   type: "textEditor",
    //   label: "Area Description",
    //   key: "areaDescription",
    //   md: 24,
    // },
  ];

  return (
    <>
      {loading && <Loader />}
      <div className="card pa-4">
        <Col md={8}>
          <FormGenerator
            formData={formData}
            customLayout
            generatorData={formGeneratorData}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            updateMode={updateMode}
          />
        </Col>
      </div>
      <div className="card pa-4 mt-4">
        <DataTable
          rowKey="id"
          columns={headers}
          dataSource={feedbackForAssets}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
        />
      </div>
    </>
  );
}

let headers = [
  { title: "Note", dataIndex: "note" },
  { title: "AssetID", dataIndex: "assetId" },
  { title: "", type: "update" },
  { title: "", type: "delete" },
];

let formRules = {
  assetId: { required: true },
  note: { required: true },
};
