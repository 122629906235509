// import months from "../utilLists/months";

import dayjs from "dayjs";
import { months } from "../lists/lists";

const dateConverter = (value, withTime = false) => {
  let date = new Date(value);

  if (withTime) {
    return dayjs(value).format("hh:mm a, DD MMMM, YYYY");
  }

  return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
};

export { dateConverter };
