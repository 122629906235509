import { Col } from "antd";
import React, { useEffect, useState } from "react";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import Loader from "../../components/reusable/loader/loader";
import DataTable from "../../components/reusable/table/dataTable";
import {
  CREATE_AREA,
  DELETE_AREA,
  GET_ALL_AREA,
  UPDATE_AREA,
} from "../../config/api/api";
import useHTTP from "../../config/http/useHTTP";
import useArea from "../../hooks/useAreas";
import usePartner from "../../hooks/usePartner";
import useZone from "../../hooks/useZone";

export default function Area() {
  let [loading, setLoading] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);
  let [zones, setZones, zoneLoading] = useZone([]);
  let [areas, setAreas, areaLoading] = useArea([]);
  let [partners, setPartners, partnerLoading] = usePartner([]);
  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  let [formData, setFormData] = useState({});

  let onSubmitHandler = async (obj) => {
    setLoading(true);

    if (updateMode) {
      // console.log(obj);
      let res = await UpdateData({ api: `${UPDATE_AREA}`, body: obj });
      if (res) {
        setAreas((prevState) =>
          prevState.map((e) => {
            if (e.id == obj.id) {
              e = { ...obj };
            }
            return e;
          })
        );
        reset();
      }
    } else {
      let res = await PostData({ api: CREATE_AREA, body: obj });
      if (res) {
        setAreas((prevState) => [...prevState, res.data]);
        reset();
      }
    }

    setLoading(false);
  };
  let onCancelHandler = async () => {
    reset();
  };
  let reset = () => {
    setFormData({});
    setUpdateMode(false);
  };

  let editHandler = async (obj) => {
    setFormData(obj);
    setUpdateMode(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };
  let deleteHandler = async (obj) => {
    if (window.confirm("Do you want to procceed?")) {
      setLoading(true);
      let res = await DeleteData({
        api: `${DELETE_AREA}/${obj.id}`,
        body: obj,
      });
      if (res) {
        setAreas((prevState) => prevState.filter((e) => e.id != obj.id));
      }
      setLoading(false);
    }
  };

  let formGeneratorData = [
    {
      type: "select",
      label: "Partner",
      key: "partnerId",
      md: 24,
      // required: true,
      placeholder: "Select Partner",
      items: partners,
      itemText: "name",
      itemValue: "id",
    },
    {
      type: "select",
      label: "Zones",
      key: "zoneId",
      md: 24,
      required: true,
      placeholder: "Select Zone",
      items: zones,
      itemText: "zoneName",
      itemValue: "id",
    },
    {
      type: "input",
      label: "Area Name",
      key: "areaName",
      md: 24,
      required: true,
      placeholder: "Area",
    },
    {
      type: "input",
      fieldType: "textarea",
      label: "Area Description",
      key: "areaDescription",
      md: 24,
      required: true,
      placeholder: "Area Description",
    },
    // {
    //   type: "textEditor",
    //   label: "Area Description",
    //   key: "areaDescription",
    //   md: 24,
    // },
  ];

  return (
    <>
      {(areaLoading || zoneLoading || loading) && <Loader />}
      <div className="card pa-4">
        <Col md={8}>
          <FormGenerator
            formData={formData}
            customLayout
            generatorData={formGeneratorData}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            updateMode={updateMode}
          />
        </Col>
      </div>
      <div className="card pa-4 mt-4">
        <DataTable
          rowKey="id"
          columns={headers}
          dataSource={areas}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
        />
      </div>
    </>
  );
}

let headers = [
  { title: "Area Name", dataIndex: "areaName" },
  { title: "Zone Name", dataIndex: "zoneName" },
  { title: "Area Description", dataIndex: "areaDescription" },
  { title: "Partner", dataIndex: "partnerName" },
  { title: "Partner Phone", dataIndex: "partnerPhone" },
  { title: "", type: "update" },
  { title: "", type: "delete" },
];

let formGeneratorData = [
  {
    type: "select",
    label: "Zones",
    key: "zoneId",
    md: 24,
    required: true,
    placeholder: "Select Zone",
  },
  {
    type: "input",
    label: "Area Name",
    key: "areaName",
    md: 24,
    required: true,
    placeholder: "Area",
  },
  {
    type: "input",
    fieldType: "textarea",
    label: "Area Description",
    key: "areaDescription",
    md: 24,
    required: true,
    placeholder: "Area Description",
  },
  // {
  //   type: "textEditor",
  //   label: "Area Description",
  //   key: "areaDescription",
  //   md: 24,
  // },
];

let formRules = {
  zoneId: { required: true },
  areaName: { required: true },
  areaDescription: { maxLen: 1000 },
};
