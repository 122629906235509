import React, { useEffect, useState } from "react";
import { GET_ALL_USER } from "../config/api/api";
import useHTTP from "../config/http/useHTTP";

export default function useUser(initValue = [], type = "all") {
  let [users, setUsers] = useState(initValue);
  let [loading, setLoading] = useState(false);

  let { GetData } = useHTTP();

  useEffect(() => {
    getData();
  }, []);

  let getData = async (obj) => {
    setLoading(true);
    let res = await GetData({ api: GET_ALL_USER });
    if (res.data) {
      setUsers(res.data);
    }
    setLoading(false);
  };

  return [users, setUsers, loading];
}
