/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
// import { Button, DatePicker, Space, version } from "antd";

import SubMenu from "../../menu/submenu";
import MenuItem from "../../menu/menuItem";
import { useLocation, useParams } from "react-router-dom";
import menuCollections from "./menuData";

function MenuGenerator({
  items = [],
  activeMenus = [],
  onClickOnMenu = () => {},
  multiple,
  spaceLeft = 15,
  submenuContainerId = "submenuContainer",
}) {
  return (
    <div id={submenuContainerId}>
      {items.map((menu, index) =>
        menu.childrens?.length ? (
          <SubMenu
            key={index}
            name={menu.name}
            icon={menu.icon}
            active={activeMenus.includes(menu.key)}
            onClick={() => onClickOnMenu(menu.key)}
            multiple={multiple}
            spaceLeft={`${spaceLeft}`}
          >
            <MenuGenerator
              items={menu.childrens}
              onClickOnMenu={onClickOnMenu}
              activeMenus={activeMenus}
              multiple={multiple}
              spaceLeft={Number(spaceLeft) + 15}
            />
          </SubMenu>
        ) : (
          <MenuItem
            key={index}
            name={menu.name}
            icon={menu.icon}
            active={activeMenus.includes(menu.key)}
            to={menu?.to}
            href={menu?.href}
            onClick={() => onClickOnMenu(menu.key)}
            style={{ paddingLeft: `${spaceLeft}px` }}
            menuKey={menu.key}
          />
        )
      )}
    </div>
  );
}
export default function Sidebar() {
  let [menuArray, setMenuArray] = useState([]);
  let [menuObjects, setMenuObjects] = useState({});
  let [activeMenus, setActiveMenus] = useState([]);
  let [submenuContainerId, setSubmenuContainerId] =
    useState("submenuContainerId");
  let location = useLocation();
  let parameter = useParams();
  useEffect(() => {
    // console.log(location, parameter);
    // setTimeout(() => {
    //   let element = document.querySelector("#submenuContainerId  .active");
    //   console.log(element);
    // }, 1000);
  }, [activeMenus]);

  useEffect(() => {
    let allMenus = {};
    let menuProccessor = (menuArray, parent) => {
      let array = menuArray.map((menu, index) => {
        menu.key = parent ? `${parent}-${index}` : `root-${index}`;
        menu.parent = parent ? `${parent}` : "root";
        allMenus[menu.key] = menu;
        if (menu?.childrens?.length) {
          menu.childrens = menuProccessor(menu.childrens, menu.key);
        }
        return menu;
      });
      return array;
    };
    let proccessedMenuArray = menuProccessor(menuCollections);
    setMenuArray(proccessedMenuArray);
    setMenuObjects(allMenus);
    // console.log(proccessedMenuArray);
  }, []);

  let menuClickHandler = (key) => {
    let activeMenuTree = [];
    let parent = menuObjects[key];
    activeMenuTree.push(parent.key);
    let count = 0;
    while (parent && parent?.parent !== "root" && count < 5) {
      parent = menuObjects[parent.parent];
      activeMenuTree.push(parent.key);

      // console.log(count, parent);
      count++;
    }
    // console.log({ activeMenuTree });

    setActiveMenus(activeMenuTree);
  };

  return (
    <div>
      <MenuGenerator
        items={menuArray}
        onClickOnMenu={menuClickHandler}
        activeMenus={activeMenus}
        multiple={false}
        submenuContainerId={submenuContainerId}
      />
    </div>
  );
}
