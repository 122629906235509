/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useHTTP from "../../config/http/useHTTP";

import * as api from "../../config/api/api";
import Loader from "../../components/reusable/loader/loader";
import { Col, Row, Table } from "antd";
import Save from "../../components/reusable/buttons/saveButton";
import DataTable from "../../components/reusable/table/dataTable";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";

export default function About() {
  let [data, setData] = useState([]);
  let [formData, setFormData] = useState({});
  let [loader, setLoader] = useState(false);
  let [updateMode, setUpdateMode] = useState(true);

  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  useEffect(() => {
    getData();
  }, []);

  let getData = async () => {
    setLoader(true);
    let res = await GetData({ api: api.GET_ABOUT });
    setLoader(false);
    console.log(res.data);
    if (res) {
      setFormData(res.data);
    }
  };

  let editHandler = (object) => {
    // console.log(object)
    setFormData(object);
    setUpdateMode(true);
    window.scrollTo(0, 0);
  };

  let onSubmitHandler = async (object) => {

    setLoader(true);
    let res = await UpdateData({ api: api.UPDATE_ABOUT, body: object });
    console.log({ res });

    setLoader(false);
  };

  let onCancelHandler = () => {
    setUpdateMode(false);
  };

  return (
    <div>
      {loader && <Loader />}

      <Row gutter={[24, 24]}>
        <Col md={12}>
          <FormGenerator
            customLayout
            generatorData={formGenerator}
            formData={formData}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            updateMode={updateMode}
          />
        </Col>
      </Row>
    </div>
  );
}

let formGenerator = [
  {
    type: "input",
    label: "Phone",
    placeholder: "Insert Phone",
    key: "phone",
  },
  {
    type: "input",
    fieldType: "textarea",
    label: "About",
    placeholder: "Insert About",
    key: "about",
  },
];

let formRules = {
  phone: {
    required: true,
    type: "phone",
  },
  about: {
    required: true,
  },
};
