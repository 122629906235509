import { Col } from "antd";
import React, { useEffect, useState } from "react";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import Loader from "../../components/reusable/loader/loader";
import DataTable from "../../components/reusable/table/dataTable";
import { CREATE_USER, DELETE_USER, UPDATE_USER } from "../../config/api/api";
import useHTTP from "../../config/http/useHTTP.js";
import useUser from "../../hooks/useUser.js";

export default function User() {
  let [loading, setLoading] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);
  let [users, setUsers, userLoading] = useUser([]);
  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  let [formData, setFormData] = useState({});

  let onSubmitHandler = async (obj) => {
    setLoading(true);

    if (updateMode) {
      // console.log(obj);
      let res = await UpdateData({ api: `${UPDATE_USER}`, body: obj });
      if (res) {
        setUsers((prevState) =>
          prevState.map((e) => {
            if (e.id == obj.id) {
              e = { ...obj };
            }
            return e;
          })
        );
        reset();
      }
    } else {
      let res = await PostData({ api: CREATE_USER, body: obj });
      if (res) {
        setUsers((prevState) => [...prevState, res.data]);
        reset();
      }
    }

    setLoading(false);
  };
  let onCancelHandler = async () => {
    reset();
  };
  let reset = () => {
    setFormData({});
    setUpdateMode(false);
  };

  let editHandler = async (obj) => {
    setFormData(obj);
    setUpdateMode(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };
  let deleteHandler = async (obj) => {
    if (window.confirm("Do you want to procceed?")) {
      setLoading(true);
      let res = await DeleteData({
        api: `${DELETE_USER}/${obj.id}`,
        body: obj,
      });
      if (res) {
        setUsers((prevState) => prevState.filter((e) => e.id != obj.id));
      }
      setLoading(false);
    }
  };
  let formRules = {
    username: { required: true },
    ...(!updateMode && { password: { required: true } }),
    roleId: { required: true },
  };

  return (
    <>
      {(userLoading || loading) && <Loader />}
      <div className="card pa-4">
        <Col md={8}>
          <FormGenerator
            formData={formData}
            customLayout
            generatorData={formGeneratorData(updateMode)}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            updateMode={updateMode}
          />
        </Col>
      </div>
      <div className="card pa-4 mt-4">
        <DataTable
          rowKey="id"
          columns={headers}
          dataSource={users}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
        />
      </div>
    </>
  );
}

let formGeneratorData = (updateMode = false) => {
  let data = [
    {
      type: "select",
      label: "Role",
      key: "roleId",
      md: 24,
      required: true,
      placeholder: "Select Role",
      items: roles,
      itemText: "role",
      itemValue: "id",
    },
    {
      type: "input",
      label: "User Name",
      key: "username",
      md: 24,
      required: true,
      placeholder: "Username",
    },
    {
      type: "input",
      label: "Password",
      key: "password",
      md: 24,
      required: true,
      placeholder: "Password",
    },
    {
      type: "select",
      label: "Active Status",
      key: "activeStatus",
      md: 24,
      required: true,
      placeholder: "ex: active, inactive",
      items: activeStatusArray,
      itemText: "activeStatus",
      itemValue: "id",
    },
    // {
    //   type: "textEditor",
    //   label: "User Description",
    //   key: "userDescription",
    //   md: 24,
    // },
  ].filter((e) =>
    updateMode ? e.key !== "password" : true
  ); /* If updateMode is on, this filter method will hide the password field */
  return data;
};

let headers = [
  { title: "User Name", dataIndex: "username" },
  { title: "Role", dataIndex: "role" },
  { title: "Active Status", dataIndex: "activeStatus" },
  { title: "", type: "update" },
  { title: "", type: "delete" },
];

let roles = [
  { id: 1, role: "Admin" },
  { id: 2, role: "Partner" },
  //   { id: 3, role: "Moderator" },
];
let activeStatusArray = [
  { id: 1, activeStatus: "Active" },
  { id: 0, activeStatus: "Inactive" },
  //   { id: 3, role: "Moderator" },
];
