import React from "react";

import axiosInstance from "./axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export default function useHTTP() {
  let navigate = useNavigate();
  let errorHandler = (error) => {
    if (error?.response?.status === 401) {
      toast(error.response.data);
      navigate("/login");
      return;
    }
    // error?.response?.status === 403 &&
    if ( Array.isArray(error?.response?.data)) {
      error.response.data.map((e) => toast(e));
      return;
    }
    if ( Array.isArray(error?.response?.data?.message)) {
      error.response.data.message.map((e) => toast(e));
      return;
    }

    toast("error occurred");
    console.log({ error: error.response});
  };

  let GetData = async ({ api, config = {} }) => {
    try {
      let data = await axiosInstance.get(api, config);
      // toast("success");
      return data;
    } catch (error) {
      errorHandler(error);

      return false;
    }
  };

  let PostData = async ({ api, body, config = {} }) => {
    try {
      let data = await axiosInstance.post(api, body, config);
      toast("success");

      return data;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };

  let UpdateData = async ({ api, body, config = {} }) => {
    try {
      let data = await axiosInstance.put(api, body, config);
      toast("success");

      return data;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };

  let DeleteData = async ({ api, config = {} }) => {
    try {
      let data = await axiosInstance.delete(api, config);
      toast("success");

      return data;
    } catch (error) {
      errorHandler(error);
      return false;
    }
  };

  return { GetData, PostData, UpdateData, DeleteData };
}
