import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import React, { Component, useState } from "react";
import {
  EditorState,
  convertToRaw,
  convertFromHTML,
  ContentState,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Button } from "antd";
import Label from "../form/label/label";

export default function ReactDraftTextEditor({
  value = "",
  onChange = () => {},
  wrapperClassName = "",
  editorClassName = "",
  editorStyle = {},
  label,
  labelClass = "label",
  labelStyle = {},
  required,
  errorText,
  errotTextClass,
  errotTextStyle,
}) {
  /* Create an Empty State */
  // let [editorState, setEditorState] = useState(EditorState.createEmpty());

  /* Create an State with iniial value */
  let [editorState, setEditorState] = useState(
    EditorState.createWithContent(
      ContentState.createFromBlockArray(convertFromHTML(value))
    )
  );

  const onEditorStateChangeHandler = (e) => {
    setEditorState(e);

    /* Passing html value to  */
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  return (
    <>
      <Label
        label={label}
        className={`${labelClass} pl-1 mb-2`}
        style={labelStyle}
        required={required}
      />
      <Editor
        // toolbarOnFocus
        // defaultEditorState={}
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName={wrapperClassName}
        editorClassName={editorClassName}
        editorStyle={{
          backgroundColor: "white",
          padding: "10px",
          minHeight: "300px",
          ...editorStyle,
        }}
        onEditorStateChange={onEditorStateChangeHandler}
      />
      <Label
        label={errorText}
        className={`${errotTextClass} pl-1 mt-1`}
        style={errotTextStyle}
        error
      />
    </>
  );
}
