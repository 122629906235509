/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

export default function MenuItem({
  name,
  icon,
  onClick = () => {},
  active = false,
  style = {},
  to,
  href,
  menuKey,
}) {
  let location = useLocation();
  let navigation = useNavigate();
  useEffect(() => {
    if (location.pathname === to) {
      onClick(menuKey);
    }
  }, []);

  let onClickHandler = () => {
    /* href is only for redirect into externel link / another website  */
    if (href) {
      window.open(href);
      // console.log("href");
    }
    if (to) {
      navigation(to);
    }
    onClick();
  };
  return (
    <div
      style={{
        ...menuStyle,
        ...(active && activeMenuStyle),
        ...style,
      }}
      className="hover_menu-item"
      onClick={onClickHandler}
    >
      {icon && <span style={iconStyle}>{icon}</span>}
      {name}
      {/* {to ? (
        <NavLink to={to} style={{ border: "1px solid red" }}>
          {icon && <span style={iconStyle}>{icon}</span>}
          {name}
        </NavLink>
      ) : (
        <>
          {icon && <span style={iconStyle}>{icon}</span>}
          {name}
        </>
      )} */}
    </div>
  );
}

let menuStyle = {
  cursor: "pointer",
  color: "var(--primary_text_color)",
  padding: "5px 15px",
  fontSize: "14px",
  fontWeight: "500",
  letterSpacing: "1px",
  display: "flex",
  alignItems: "flex-start",
  wordBreak: "break-word",
};
let activeMenuStyle = {
  backgroundColor: "var(--active_menu_item_color)",
  color: "var(--active_menu_item_text_color)",
};

let iconStyle = {
  marginRight: "10px",
  fontSize: "inherit",
};
