/**
 * author: hasib
 */
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

export default function Save({
  btnName = "Save",
  onClick = () => {},
  icon = null,
  ...args
}) {
  return (
    <>
      <Button
        type="primary"
        size="medium"
        {...args}
        onClick={onClick}
        style={{ backgroundColor: "#1677ff" }}
      >
        {btnName}
      </Button>
    </>
  );
}
