import React from "react";

import { Input } from "antd";
import Label from "../label/label";

export default function InputComponent({
  label = "",
  errorText = "",
  labelClass = "mb-2",
  labelStyle = {},
  errotTextClass = "",
  errotTextStyle = {},
  fieldType = "input",
  required,
  size="large",
  ...args
}) {
  return (
    <>
      <Label
        label={label}
        className={`${labelClass} pl-1`}
        style={labelStyle}
        required={required}
      />
      {fieldType === "input" ? (
        <Input  {...args} size={size} status={errorText?.length ? "error" : false}   />
      ) : (
        <Input.TextArea  {...args} size={size} status={errorText?.length ? "error" : false} />
      )}
      <Label
        label={errorText}
        className={`${errotTextClass} pl-1 mt-1`}
        style={errotTextStyle}
        error
      />
    </>
  );
}
