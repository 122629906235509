/* eslint-disable react-hooks/exhaustive-deps */

/*
 * Author: Hasib
 */

import React, { useEffect, useState } from "react";
import { Table, Button, Row, Col } from "antd";
import { dateConverter } from "../../../utils/utilityFunctions/dateConverter.js";
import Download from "../buttons/downloadButton.js";

const DataTable = ({
  columns = [],
  dataSource = [],
  validationSource = [],
  cellInputHandler,
  editHandler = () => {},
  deleteHandler = () => {},
  tableHeadline = "",
  pagination = false,
  allowFilter = false,
  ...args
}) => {
  let [headerCol, setHeaderCol] = useState([...columns]);

  useEffect(() => {
    let data = columns.map((header, index) => {
      if (header.type === "update") {
        header = {
          ...header,
          render: (value, object) => (
            <>
              <Button onClick={() => editHandler(object)}>Update</Button>
            </>
          ),
        };
      } else if (header.type === "delete") {
        header = {
          ...header,
          render: (value, object) => (
            <>
              <Button
                danger
                onClick={() => deleteHandler(object)}
                className="table_del_button"
              >
                Delete
              </Button>
            </>
          ),
        };
      } else if (header.type === "date") {
        header = {
          ...header,
          render: (value, object) => <>{dateConverter(value)}</>,
        };
      } else if (header.type === "image") {
        header = {
          ...header,
          render: (value, object) => (
            <>
              <img
                src={value ? value : "https://i.ibb.co/2hcLX81/user.png"}
                height="70px"
                width="70px"
                alt="student"
              />
            </>
          ),
        };
      } else if (header.type === "signImage") {
        header = {
          ...header,
          render: (value, object) => (
            <>
              <img
                src={value ? value : "https://i.ibb.co/2hcLX81/user.png"}
                width="130px"
                alt="student"
              />
            </>
          ),
        };
      }
      if (header.sortable) {
        let sorter =
          header.sortableType && header.sortableType === "number"
            ? (a, b) => a[header.dataIndex] - b[header.dataIndex]
            : (a, b) => a[header.dataIndex] - b[header.dataIndex];

        header = {
          ...header,
          sorter,
        };
      }

      return header;
    });

    setHeaderCol(data);
  }, [columns]);

  return (
    <>
      <div className="table_parent overflow-auto w-100">
        {/* Table Headline */}
        <div className="text-xl mb-2 ml-2">
          <span className={`${tableHeadline ? "mr-2" : ""}`}>
            {tableHeadline}
          </span>
          {dataSource?.length} items found
        </div>

        <Table
          columns={headerCol}
          dataSource={dataSource}
          /* Do not enable pagination. if pagination is enabled, accurate row index will not be passed in 'cellInputHandler' function props */
          pagination={pagination}
          {...args}
        ></Table>
      </div>

      {/* {downloadPdfHandler && (
        <div className="mt-8 text-end">
          <Download onClick={() => downloadPdfHandler(filteredDataSource)} />
        </div>
      )} */}
    </>
  );
};

export default DataTable;
