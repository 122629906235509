import { Image } from "antd";
import React, { useEffect, useState } from "react";

export default function ImageComponent({
  src,
  preview = false,
  height,
  className = "",
}) {
  let [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    console.log(src);
    if (src instanceof File) {
      setImgSrc(URL.createObjectURL(src));
    } else if (src instanceof Blob) {
      setImgSrc(src);
    } else {
      setImgSrc(src);
    }
  }, [src]);

  return (
    <Image
      className={className}
      height={height}
      preview={preview}
      src={imgSrc}
    />
  );
}
