import React, { useEffect, useState } from "react";
import { GET_ALL_DESIGNATION } from "../config/api/api";
import useHTTP from "../config/http/useHTTP";

export default function useDesignation(initValue = []) {
  let [data, setData] = useState(initValue);
  let [loading, setLoading] = useState(false);

  let { GetData } = useHTTP();

  useEffect(() => {
    getData();
  }, []);

  let getData = async () => {
    setLoading(true);
    let res = await GetData({ api: GET_ALL_DESIGNATION });
    if (res.data) {
      setData(res.data);
    }
    setLoading(false);
  };

  return [data, setData, loading];
}
