/**
 * author: hasib
 */
import { Button } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
export default function Search({
  btnName = "Search",
  onClick = () => {},
  ...args
}) {
  return (
    <>
      <Button
        type="primary"
        size="large"
        {...args}
        onClick={onClick}
        style={{ backgroundColor: "#1677ff" }}
      >
        <span className="d-flex align-items-center justify-content-center">
          <SearchOutlined className="mr-2" /> {btnName}
          {/* <FileSearchOutlined /> */}
        </span>
      </Button>
    </>
  );
}
