/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import MenuItem from "./menuItem";

export default function SubMenu({
  children,
  name,
  icon,
  style = {},
  submenuStyle = {},
  active = false,
  multiple,
  onClick = () => {},
  spaceLeft,
}) {
  let [isExpand, setIsExpand] = useState(active);

  useEffect(() => {
    active && setIsExpand(active);
  }, [active]);
  // useEffect(() => {
  //   multiple && setIsExpand(active);
  // }, [active]);

  let onClickHandler = () => {
    setIsExpand((prevState) => !prevState);
    // setIsExpand(active);
    onClick();
  };

  return (
    <div>
      <div
        className="submenu_container"
        style={{
          ...(active && {
            backgroundColor: "var(--active_submenu_color)",
            color: "var(--active_submenu_text_color)",
          }),
        }}
        onClick={onClickHandler}
      >
        <MenuItem
          name={name}
          icon={icon}
          style={{
            ...style,
            ...(active && { color: "var(--active_submenu_text_color)" }),
            ...(spaceLeft && { paddingLeft: `${spaceLeft}px` }),
          }}
        />
        <DropDownIcon
          isExpand={isExpand}
          style={{
            padding: "8px 15px",
            ...(active && { color: "var(--active_submenu_text_color)" }),
          }}
        />
      </div>

      {/* Childrens */}
      <div
        style={{
          transition: "max-height 0.2s ease-in ",
          overflow: "hidden",
          maxHeight: "0",

          ...(isExpand && { maxHeight: "1000px" }),
          // ...(active && isExpand && { marginTop: "5px" }),
          ...submenuStyle,
        }}
      >
        {children}
      </div>
    </div>
  );
}

function DropDownIcon({ isExpand = false, style = {}, fontSize = "10px" }) {
  let dropdownIconStyle = {
    height: fontSize,
    width: "2px",
    backgroundColor: `${style?.color ? style.color : "rgba(0,0,0,.9)"}`,
    fontWeight: "500",
    transition: "ease-in-out .2s",
    position: "absolute",
    top: "0px",
  };

  let rotationValue = 38;

  return (
    <div style={{ ...style }}>
      <span
        style={{
          height: "10px",
          position: "relative",
        }}
      >
        <DropDownIconBar
          dropdownIconStyle={dropdownIconStyle}
          isExpand={isExpand}
          rotationValue={-rotationValue}
        />
        <DropDownIconBar
          dropdownIconStyle={dropdownIconStyle}
          isExpand={isExpand}
          rotationValue={rotationValue}
        />
      </span>
    </div>
  );
}

function DropDownIconBar({ dropdownIconStyle, isExpand, rotationValue }) {
  return (
    <div
      className="dropdown-icon"
      style={{
        ...dropdownIconStyle,
        transform: `rotate(${isExpand ? -rotationValue : rotationValue}deg)`,
        transformOrigin: `${isExpand ? "top" : "bottom"}`,
      }}
    ></div>
  );
}
