import { Col } from "antd";
import React, { useEffect, useState } from "react";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import Loader from "../../components/reusable/loader/loader";
import DataTable from "../../components/reusable/table/dataTable";
import {
  CREATE_ZONE,
  DELETE_ZONE,
  GET_ALL_ZONE,
  UPDATE_ZONE,
} from "../../config/api/api";
import useHTTP from "../../config/http/useHTTP";
import useZone from "../../hooks/useZone";

export default function Zone() {
  let [loading, setLoading] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);
  let [zones, setZones, zoneLoading] = useZone([]);
  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  let [formData, setFormData] = useState({});

  let onSubmitHandler = async (obj) => {
    setLoading(true);

    if (updateMode) {
      // console.log(obj);
      let res = await UpdateData({ api: `${UPDATE_ZONE}`, body: obj });
      if (res) {
        setZones((prevState) =>
          prevState.map((e) => {
            if (e.id == obj.id) {
              e = { ...obj };
            }
            return e;
          })
        );
        reset();
      }
    } else {
      let res = await PostData({ api: CREATE_ZONE, body: obj });
      if (res) {
        setZones((prevState) => prevState.push(res.data));
        reset();
      }
    }

    setLoading(false);
  };
  let onCancelHandler = async () => {
    reset();
  };
  let reset = () => {
    setFormData({});
    setUpdateMode(false);
  };

  let editHandler = async (obj) => {
    setFormData(obj);
    setUpdateMode(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };
  let deleteHandler = async (obj) => {
    if (window.confirm("Do you want to procceed?")) {
      setLoading(true);
      let res = await DeleteData({
        api: `${DELETE_ZONE}/${obj.id}`,
        body: obj,
      });
      if (res) {
        setZones((prevState) => prevState.filter((e) => e.id != obj.id));
      }
      setLoading(false);
    }
  };

  return (
    <>
      {(zoneLoading || loading) && <Loader />}
      <div className="card pa-4">
        <Col md={8}>
          <FormGenerator
            formData={formData}
            customLayout
            generatorData={formGeneratorData}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            updateMode={updateMode}
          />
        </Col>
      </div>
      <div className="card pa-4 mt-4">
        <DataTable
          rowKey="id"
          columns={headers}
          dataSource={zones}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          // tableHeadline="Zone"
        />
      </div>
    </>
  );
}

let headers = [
  { title: "Zone Name", dataIndex: "zoneName" },
  { title: "Zone Description", dataIndex: "zoneDescription" },
  { title: "", type: "update" },
  { title: "", type: "delete" },
];

let formGeneratorData = [
  {
    type: "input",
    label: "Zone Name",
    key: "zoneName",
    md: 24,
    required: true,
    placeholder: "Zone",
  },
  {
    type: "input",
    fieldType: "textarea",
    label: "Zone Description",
    key: "zoneDescription",
    md: 24,
    required: true,
    placeholder: "Zone Description",
  },
  // {
  //   type: "textEditor",
  //   label: "Zone Description",
  //   key: "zoneDescription",
  //   md: 24,
  // },
];

let formRules = {
  zoneName: { required: true },
  zoneDescription: { maxLen: 1000 },
};
