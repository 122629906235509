import React from "react";

export default function Label({
  className = "label",
  style = {},
  label = "",
  required = false,
  error = false,
}) {
  return (
    <div
      className={` ${className}  ${error ? "error_text" : 'label' } `}
      style={{ ...style }}
    >
      {label} {required && <span className="ml-1 error_text">*</span>}
    </div>
  );
}
