// const HOST = `http://localhost:5000`;
// const HOST = `http://143.244.128.66:5000`;
// const HOST = `http://localhost:8000`;
const HOST = `https://sohokormi-backend.ssc2010bangladesh.com`;

// const SLUG = "api/admin";
const BASE = `${HOST}/admin`;

export const LOGIN = `${HOST}/auth/login`;

export const CREATE_PROFESSION = `${BASE}/profession`;
export const GET_ALL_PROFESSION = `${BASE}/profession/all`;
export const UPDATE_PROFESSION = `${BASE}/profession`;
export const DELETE_PROFESSION = `${BASE}/profession`; /* /{id} */



export const CREATE_MEMBER = `${BASE}/member`;
export const GET_ALL_MEMBER = `${BASE}/member/all`;
export const UPDATE_MEMBER = `${BASE}/member`;
export const DELETE_MEMBER = `${BASE}/member`; /* /{id} */

export const PROFESSIONS = `${BASE}/profession/all`;
export const DESIGNATIONS = `${BASE}/designation/all`;
export const DISTICTS = `${BASE}/districts`;
export const UPAZILA_BY_DISTRICT_ID = `${BASE}/upazilas-by-district`; /* ?districtId={districtId} */

/* ---------------------------------------------------- */
/* ---------------------------------------------------- */
/* ---------------------------------------------------- */
/* ---------------------------------------------------- */
/* ---------------------------------------------------- */

export const GET_ALL_DEPARTMENT = `${BASE}/department/get/all`;
export const CREATE_DEPARTMENT = `${BASE}/department/create`;
export const UPDATE_DEPARTMENT = `${BASE}/department/update`;
export const DELETE_DEPARTMENT = `${BASE}/department/delete`; /* /:departmentId */

export const GET_ALL_DESIGNATION = `${BASE}/designation/get/all`;
export const CREATE_DESIGNATION = `${BASE}/designation/create`;
export const UPDATE_DESIGNATION = `${BASE}/designation/update`;
export const DELETE_DESIGNATION = `${BASE}/designation/delete`; /* /:departmentId */

export const GET_ALL_INSTITUTE = `${BASE}/institute/get/all`;
export const CREATE_INSTITUTE = `${BASE}/institute/create`;
export const UPDATE_INSTITUTE= `${BASE}/institute/update`;
export const DELETE_INSTITUTE = `${BASE}/institute/delete`; /* /:departmentId */
export const UPDATE_ABOUT= `${BASE}/about/update`;


export const GET_ABOUT = `${HOST}/about`;
export const GET_ALL_DISTRICT = `${HOST}/districts`;
export const GET_ALL_UPAZILA_BY_DISCTRICT_ID = `${HOST}/upazila`; /* /:districtId */

export const GET_ALL_TEACHER_BY_IDENTIFIER = `${BASE}/teacher/get/by/identifier`; /* /{identifier} */
export const CREATE_HEADMASTER = `${BASE}/teacher/create/headmaster `; 
export const CREATE_TEACHERS = `${BASE}/teacher/create`; 
export const UPDATE_TEACHERS = `${BASE}/teacher/update`; 





/* ---------------------------------------------------- */
/* ---------------------------------------------------- */
/* ---------------------------------------------------- */
/* ---------------------------------------------------- */
/* ---------------------------------------------------- */

export const CREATE_ZONE = `${BASE}/admin/zone/create`;
export const GET_ALL_ZONE = `${BASE}/admin/zone/get/all`;
export const UPDATE_ZONE = `${BASE}/admin/zone/update`;
export const DELETE_ZONE = `${BASE}/admin/zone/delete`; /*  /{id} */

export const CREATE_AREA = `${BASE}/admin/area/create`;
export const GET_ALL_AREA = `${BASE}/admin/area/get/all`;
export const GET_ALL_AREA_BY_ZONE = `${BASE}/admin/area/get/by-zone`; /* /zoneId */
export const UPDATE_AREA = `${BASE}/admin/area/update`;
export const DELETE_AREA = `${BASE}/admin/area/delete`; /*  /{id} */

export const CREATE_ASSIGNMENT_TYPE = `${BASE}/admin/assignment-type/create`;
export const GET_ALL_ASSIGNMENT_TYPE = `${BASE}/admin/assignment-type/get/all`;
export const UPDATE_ASSIGNMENT_TYPE = `${BASE}/admin/assignment-type/update`;
export const DELETE_ASSIGNMENT_TYPE = `${BASE}/admin/assignment-type/delete`; /*  /{id} */

export const CREATE_ASSET_TYPE = `${BASE}/admin/asset-type/create`;
export const GET_ALL_ASSET_TYPE = `${BASE}/admin/asset-type/get/all`;
export const UPDATE_ASSET_TYPE = `${BASE}/admin/asset-type/update`;
export const DELETE_ASSET_TYPE = `${BASE}/admin/asset-type/delete`; /*  /{id} */

export const CREATE_ASSET_STATUS = `${BASE}/admin/asset-status/create`;
export const GET_ALL_ASSET_STATUS = `${BASE}/admin/asset-status/get/all`;
export const UPDATE_ASSET_STATUS = `${BASE}/admin/asset-status/update`;
export const DELETE_ASSET_STATUS = `${BASE}/admin/asset-status/delete`; /*  /{id} */

export const CREATE_USER = `${BASE}/admin/user/create`;
export const GET_ALL_USER = `${BASE}/admin/user/get/all`;
export const UPDATE_USER = `${BASE}/admin/user/update`;
export const DELETE_USER = `${BASE}/admin/user/delete`; /*  /{id} */

export const CREATE_PARTNER = `${BASE}/admin/partner/create`;
export const GET_ALL_PARTNER = `${BASE}/admin/partner/get/all`;
export const GET_ALL_ACTIVE_PARTNER = `${BASE}/admin/partner/get/all/active-partner`;
export const UPDATE_PARTNER = `${BASE}/admin/partner/update`;
export const DELETE_PARTNER = `${BASE}/admin/partner/delete`; /*  /{id} */

export const CREATE_ASSET = `${BASE}/admin/asset/create`;
export const GET_ALL_ASSET = `${BASE}/admin/asset/get/all`;
export const GET_ALL_ASSET_BY_AREA = `${BASE}/admin/asset/get/all/by-area`; /* /areaId */
export const GET_ASSET_BY_ASSET_ID = `${BASE}/admin/asset/get/by-id`; /* /assetId */
export const GET_ASSET_FEEDBAKC_BY_PARTNER_BY_ASSET_ID = `${BASE}/admin/asset-feedback-by-partner/get/by-asset-id`; /* /assetId */
export const UPDATE_ASSET = `${BASE}/admin/asset/update`;
export const DELETE_ASSET = `${BASE}/admin/asset/delete`; /*  /{id} */

export const CREATE_FEEDBACK_FOR_PARTNER = `${BASE}/admin/partner-feedback-by-management/create`;
export const GET_ALL_FEEDBACK_FOR_PARTNER = `${BASE}/admin/partner-feedback-by-management/get/all`;
export const UPDATE_FEEDBACK_FOR_PARTNER = `${BASE}/admin/partner-feedback-by-management/update`;
export const DELETE_FEEDBACK_FOR_PARTNER = `${BASE}/admin/partner-feedback-by-management/delete`; /*  /{id} */

export const CREATE_FEEDBACK_FOR_ASSET = `${BASE}/admin/asset-feedback-by-management/create`;
export const GET_ALL_FEEDBACK_FOR_ASSET = `${BASE}/admin/asset-feedback-by-management/get/all`;
export const UPDATE_FEEDBACK_FOR_ASSET = `${BASE}/admin/asset-feedback-by-management/update`;
export const DELETE_FEEDBACK_FOR_ASSET = `${BASE}/admin/asset-feedback-by-management/delete`; /*  /{id} */
