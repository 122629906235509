/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import InputComponent from "../../components/reusable/form/input/input";
import Label from "../../components/reusable/form/label/label";
import SelectComponent from "../../components/reusable/form/select/select";
import useHTTP from "../../config/http/useHTTP";
import Loader from "../../components/reusable/loader/loader";
import moment from "moment";
import ReactDraftTextEditor from "../../components/reusable/textEditor/reactDraftTextEditor";
import ImageUploader from "../../components/reusable/form/filePicker/imageUploader";

export default function Home() {
  let [data, setData] = useState([]);
  let { GetData } = useHTTP();

  let [loader, setLoader] = useState(false);

  useEffect(() => {}, []);

  return (
    <div>
      {loader && <Loader />}
      <Row>
        <Col md={24}>
          {/* <ReactDraftTextEditor /> */}
          <ImageUploader
            multiple
            // value={[
            //   "https://therichpost.com/wp-content/uploads/2020/12/Reactjs-Multiple-Images-Upload-with-Preview-and-Delete.png",
            // ]}
          />
        </Col>
      </Row>
    </div>
  );
}

/* --------------------------- */
/* ------demo data ----------- */
/* --------------------------- */

let items = [
  {
    name: "One",
    id: "1",
  },
  {
    name: "Two",
    id: "2",
  },
];

let countries = [
  "Bangladesh",
  "Bhutan",
  "India",
  "Maldive",
  "Nepal",
  "Pakistan",
  "Sri Lanka",
];

let cities = [
  "Dhaka",
  "Kathmundu",
  "Dilhi",
  "Washington DC",
  "London",
  "Karachi",
  "Mosco",
];

let formRules = {
  contact: {
    required: true,
    minLen: 10,
  },

  country: {
    required: true,
  },
  city: {
    required: true,
  },
  addressTwo: {
    required: true,
  },
  addressThree: {
    required: true,
  },
  addressFour: {
    required: true,
  },
  addressFive: {
    required: true,
  },
};

let formLayout = {
  xxl: 12,
  xl: 12,
  lg: 12,
  md: 24,
  sm: 24,
  xs: 24,
};

let formGenerator = [
  {
    type: "input",
    label: "Contact Name",
    key: "contact",
    required: true,
    placeholder: "Admin",
    ...formLayout,
  },

  {
    type: "select",
    label: "Country",
    key: "country",
    required: true,

    ...formLayout,
    items: countries,
    placeholder: "India",
  },

  {
    type: "select",
    label: "City",
    key: "city",
    required: true,

    ...formLayout,
    items: items,
    itemText: "name",
    itemValue: "id",
    placeholder: "Chenai",
  },

  {
    type: "input",
    label: "Address Two",
    key: "addressTwo",
    placeholder: "Address",
    ...formLayout,
  },
  {
    type: "input",
    label: "Address Three",
    key: "addressThree",
    placeholder: "Address",

    ...formLayout,
  },
  {
    type: "input",
    label: "Address Four",
    key: "addressFour",
    placeholder: "Address",
    ...formLayout,
  },
  {
    type: "input",
    label: "Address Five",
    key: "addressFive",
    placeholder: "Address",
    ...formLayout,
  },
];

let formValue = {
  contact: "Demo and Dummy Data",
  country: "Bangladesh",
  city: "2",
  addressTwo: "Demo and Dummy Data",
  addressThree: "Demo and Dummy Data",
  addressFour: "Demo and Dummy Data",
  addressFive: "Demo and Dummy Data",
};

let errorData = {
  contact: "",
  country: "",
  city: "",
  addressTwo: "",
  addressThree: "",
  addressFour: "",
  addressFive: "",
};
