export const HOME = "/";
export const PROFESSION = "/profession";


export const LOGIN = "/login";
export const DASHBOARD = "/dashboard";

export const TEST_PARAM = "/test/param/:paramOne/:two_para";
export const TEST_PARAM_FUNC = (paramOne, paramTwo) =>
  `/test/param/${paramOne}/${paramTwo}`;
// export const


export const DESIGNATION = "/designation";
export const DEPARTMENT = "/department";
export const INSTITUTE = "/institute";
export const MEMBER = "/member";
export const ABOUT = "/about";



export const ZONE = "/zone";
export const AREA = "/area";
export const ASSIGNMENT_TYPE = "/assignment-type";
export const ASSET_TYPE = "/asset-type";
export const ASSET_STATUS = "/asset-status";

export const USER = "/user";
export const PARTNER = "/partner";
export const ASSET = "/asset";
export const ASSET_PROFILE = "/asset-profile";
export const FEEDBACK_FOR_PARTNER = "/feedback-for-partner";
export const FEEDBACK_FOR_ASSET = "/feedback-for-asset";
