import React from "react";
import Home from "../../pages/home/home";
import Dashboard from "../../pages/home/dashboard";

import * as slug from "./slug";
import Login from "../../pages/login/login";

import Profession from "../../pages/settings/profession";
import Department from "../../pages/settings/department";
import Member from "../../pages/settings/member";
import ParameterTest from "../../pages/test/param";
import Zone from "../../pages/config/zone";
import Area from "../../pages/config/area";
import AssignmentType from "../../pages/config/assignmentType";
import AssetType from "../../pages/config/assetType";
import AssetStatus from "../../pages/config/assetStatus";
import User from "../../pages/user/user";
import Partner from "../../pages/user/partner";
import Asset from "../../pages/asset/asset";
import FeedbackForPartner from "../../pages/feedback/feedbackForPartner";
import FeedbackForAsset from "../../pages/feedback/feedbackForAsset";
import AssetProfile from "../../pages/asset/assetProfile";
import Designation from "../../pages/settings/designation";
import Institute from "../../pages/settings/institute";
import About from "../../pages/settings/about";

// const Home = React.lazy(() => import("../../pages/home/home"));
// const Dashboard = React.lazy(() => import("../../pages/home/dashboard"));

const routes = [
  {
    path: slug.ZONE,
    element: <Zone />,
  },
  {
    path: slug.AREA,
    element: <Area />,
  },
  {
    path: slug.ASSIGNMENT_TYPE,
    element: <AssignmentType />,
  },
  {
    path: slug.ASSET_TYPE,
    element: <AssetType />,
  },
  {
    path: slug.ASSET_STATUS,
    element: <AssetStatus />,
  },
  {
    path: slug.USER,
    element: <User />,
  },
  {
    path: slug.PARTNER,
    element: <Partner />,
  },
  {
    path: slug.ASSET,
    element: <Asset />,
  },
  {
    path: slug.ASSET_PROFILE,
    element: <AssetProfile />,
  },
  {
    path: slug.FEEDBACK_FOR_PARTNER,
    element: <FeedbackForPartner />,
  },
  {
    path: slug.FEEDBACK_FOR_ASSET,
    element: <FeedbackForAsset />,
  },

  /*------------------------------------ */
  /*------------------------------------ */
  /*------------------------------------ */
  /*------------------------------------ */
  {
    path: slug.DEPARTMENT,
    element: <Department />,
  },
  {
    path: slug.DESIGNATION,
    element: <Designation />,
  },
  {
    path: slug.INSTITUTE,
    element: <Institute />,
  },
  {
    path: slug.MEMBER,
    element: <Member />,
  },
  {
    path: slug.ABOUT,
    element: <About />,
  },
  /*------------------------------------ */
  /*------------------------------------ */
  /*------------------------------------ */
  /*------------------------------------ */
  {
    path: slug.HOME,
    element: <Home />,
  },
  {
    path: slug.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: slug.PROFESSION,
    element: <Profession />,
  },
  

  {
    path: slug.TEST_PARAM,
    element: <ParameterTest />,
  },
  //   {
  //     path: slug.LOGIN,
  //     element: <Login />,
  //   },
];

export default routes;
