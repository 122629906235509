/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import useHTTP from "../../config/http/useHTTP";
import Loader from "../../components/reusable/loader/loader";
import { Button, Col, Row, Table } from "antd";
import DataTable from "../../components/reusable/table/dataTable";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import InputComponent from "../../components/reusable/form/input/input";
import Search from "../../components/reusable/buttons/searchButton";
import {
  CREATE_HEADMASTER,
  CREATE_TEACHERS,
  GET_ALL_DISTRICT,
  GET_ALL_TEACHER_BY_IDENTIFIER,
  UPDATE_TEACHERS,
} from "../../config/api/api";
import useDepartment from "../../hooks/useDepartment";
import useDesignation from "../../hooks/useDesignation";
import Delete from "../../components/reusable/buttons/deleteButton";
import Save from "../../components/reusable/buttons/saveButton";
import Update from "../../components/reusable/buttons/updateButton";
import { useLocation } from "react-router-dom";

export default function Member() {
  let [loading, setLoading] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);
  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();
  let [formData, setFormData] = useState({});
  let [identifier, setIdentifier] = useState(null);
  let [instituteData, setInstituteData] = useState({});
  let [teachersData, setTeachersData] = useState([]);
  let [headmasterData, setHeadmasterData] = useState({});
  let [isHeadmasterCreated, setIsHeadmasterCreated] = useState(false);

  let [departments, setDepartments, departmentLoader] = useDepartment();
  let [designations, setDesignations, designationLoader] = useDesignation();

  let [formDataArray, setFormDataArray] = useState([]);
  const location = useLocation();


  useEffect(()=>{
    const queryParams = new URLSearchParams(location.search);
    const identifier = queryParams.get('identifier');
    setIdentifier(identifier)
    getInstituteData(identifier)
  },[])

  let searchHanlder = async () => {
    await getInstituteData(identifier)
  };
  let getInstituteData = async (identifier) => {
    if (identifier) {
      setInstituteData({});
      setTeachersData([]);
      setHeadmasterData({});
      setIsHeadmasterCreated(false);

      setLoading(true);
      // let res = await GetData({
      //   api: `${GET_ALL_TEACHER_BY_IDENTIFIER}/${identifier}`,
      //   config: { headers: { identifier } },
      // });
    let res = await GetData({ api: `${GET_ALL_TEACHER_BY_IDENTIFIER}/${identifier}` });

      console.log(res.data);
      if (res) {
        setInstituteData(res?.data?.institute ? res?.data?.institute : {});
        setTeachersData(res?.data?.teachers ? res?.data?.teachers : []);

        let headmaster = res?.data?.teachers?.find((e) => e.roleId == 2);
        if (headmaster) {
          setHeadmasterData(headmaster);
          setIsHeadmasterCreated(true);
        }
      }
      setLoading(false);
    }
  };



  let onSubmitHandler = async (obj) => {
    if (updateMode) {
      setLoading(true);
      let res = await UpdateData({ api: UPDATE_TEACHERS, body: obj });

      if (res) {
        reset();
      }
      setLoading(false);

      return;
    }
    obj = {
      ...obj,
      id: new Date().getTime(),
      designation: designations.find((e) => e.id == obj.designationId)
        ?.designation,
      department: departments.find((e) => e.id == obj.departmentId)?.department,
    };

    if (formDataArray.filter((e) => e.phone == obj.phone).length) {
      window.alert("Phone already added");
      return;
    }

    setFormDataArray((prevState) => [...prevState, obj]);
    setFormData({});
  };
  let headmasterSubmitHandler = async (obj) => {
    setLoading(true);
    let res;
    obj = {...obj, instituteId: instituteData.id}
    if (isHeadmasterCreated) {
      res = await UpdateData({ api: UPDATE_TEACHERS, body: obj });
    }else{
      res = await PostData({api:CREATE_HEADMASTER, body: obj})
    }
    setLoading(false);
  };
  let reset = () => {
    setFormData({});
    setUpdateMode(false);
  };

  let saveHandler = async () => {
    if (formDataArray.length) {
      setLoading(true);

      let res = await PostData({
        api: CREATE_TEACHERS,
        body: { instituteId: instituteData.id, teachers: formDataArray },
      });

      if (res) {
        reset();
      }

      setLoading(false);
    }
  };
  let updateHandler = (obj) => {
    setFormData(obj);
    setUpdateMode(true);
  };
  let formDataRemoveHandler = (obj) => {
    if (window.confirm()) {
      setFormDataArray((prevState) => prevState.filter((e) => e.id != obj.id));
    }
  };

  let formGeneratorData = [
    {
      type: "select",
      label: "Department",
      key: "departmentId",
      md: 24,
      // required: true,
      placeholder: "Select Department",
      items: departments,
      itemText: "department",
      itemValue: "id",
      required: true,
    },
    {
      type: "select",
      label: "Designation",
      key: "designationId",
      md: 24,
      // required: true,
      placeholder: "Select Designation",
      items: designations,
      itemText: "designation",
      itemValue: "id",
      required: true,
    },
    {
      type: "input",
      label: "Name",
      key: "name",
      md: 24,
      required: true,
      placeholder: "Name",
    },
    {
      type: "input",
      label: "Phone",
      key: "phone",
      md: 24,
      placeholder: "Phone",
      required: true,
    },
  ];

  let formDataHeader = [
    { title: "Department", dataIndex: "department" },
    { title: "Designation", dataIndex: "designation" },
    { title: "Name", dataIndex: "name" },
    { title: "Phone", dataIndex: "phone" },
    {
      title: "",
      render: (cellValue, rowValue) => (
        <Delete onClick={() => formDataRemoveHandler(rowValue)} />
      ),
    },
  ];
  let headers = [
    { title: "Department", dataIndex: "department" },
    { title: "Designation", dataIndex: "designation" },
    { title: "Name", dataIndex: "name" },
    { title: "Phone", dataIndex: "phone" },
    {
      title: "",
      render: (cellValue, rowValue) => (
        <Update onClick={() => updateHandler(rowValue)} />
      ),
    },
  ];

  return (
    <div>
      {loading && <Loader />}

      <div className="card pa-4">
        <Col md={6}>
          <InputComponent
            label="Identifier"
            placeholder="Insert Identifier"
            value={identifier}
            onChange={(e) => setIdentifier(e.target.value)}
          />
        </Col>
        <Col md={6}>
          <Search onClick={searchHanlder} />
        </Col>
      </div>

      {instituteData?.id && (
        <>
          <Row className="card pa-4 mt-2">
            <Col md={24} className="mb-4">
              <div className="text-2xl">Basic Information</div>
            </Col>
            {details.map((e, index) => (
              <Col key={index} md={4} className="mb-1">
                <div className="border-l-4 border-indigo-500 pl-2">
                  <label className="text-md">{e.name}</label>
                  <p className="text-lg font-semibold">
                    {instituteData?.[e.key]}
                  </p>
                </div>
              </Col>
            ))}
          </Row>

          <Row className="card pa-4 mt-2">
            <DataTable
              columns={headers}
              dataSource={teachersData}
              rowKey="userId"
            />
          </Row>

          <Row className="mt-8">
            <Col md={24} className="card pa-4">
              <FormGenerator
                formData={headmasterData}
                generatorData={formGeneratorData}
                formRules={formRules}
                onSubmit={headmasterSubmitHandler}
                // onCancel={reset}
                updateMode={isHeadmasterCreated}
                headline={`Headmaster Form `}
              />
              <br />
              {headmasterData?.phone ? <div>{`default username: ${instituteData.identifier}_${headmasterData.phone}, password: ${headmasterData.phone}`}</div> : ''}
            </Col>
            <Col md={24} className="mt-4 card pa-4">
              <FormGenerator
                formData={formData}
                generatorData={formGeneratorData}
                formRules={formRules}
                onSubmit={onSubmitHandler}
                onCancel={reset}
                updateMode={updateMode}
                headline="Teachers Form"
              />
            </Col>
            {!updateMode && formDataArray.length > 0 && (
              <Col md={24} className="mt-4 card pa-4">
                <DataTable
                  rowKey="id"
                  dataSource={formDataArray}
                  columns={formDataHeader}
                />

                <Save className="mt-4" onClick={saveHandler} />
              </Col>
            )}
          </Row>
        </> 
      )}

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
}

let details = [
  {
    name: "Name",
    key: "name",
  },
  {
    name: "EIIN",
    key: "eiin",
  },
  {
    name: "Identifier",
    key: "identifier",
  },
  {
    name: "District",
    key: "district",
  },
  {
    name: "Upazila",
    key: "upazila",
  },
  {
    name: "Phone",
    key: "phone",
  },
  {
    name: "Version",
    key: "version",
  },
  {
    name: "Is Active",
    key: "isActive",
  },
];

let formRules = {
  departmentId: { required: true },
  designationId: { required: true },
  name: { required: true },
  phone: {
    required: true,
    // type: "phone"
  },
};
