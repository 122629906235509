/**
 * author: hasib
 */
import { Button } from "antd";

export default function Update({
  btnName = "Update",
  onClick = () => {},
  ...args
}) {
  return (
    <>
      <Button
        type="primary"
        size="medium"
        {...args}
        className={` ${args.className ? args.className : ""} success_btn`}
        onClick={onClick}
      >
        {btnName}
      </Button>
    </>
  );
}
