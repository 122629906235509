import React, { useEffect, useState } from "react";
import { GET_ALL_ZONE } from "../config/api/api";
import useHTTP from "../config/http/useHTTP";

export default function useZone() {
  let [zones, setZones] = useState([]);
  let [loading, setLoading] = useState(false);

  let { GetData } = useHTTP();

  useEffect(() => {
    getData();
  }, []);

  let getData = async (obj) => {
    setLoading(true);
    let res = await GetData({ api: GET_ALL_ZONE });
    if (res.data) {
      setZones(res.data);
    }
    setLoading(false);
  };

  return [zones, setZones, loading];
}
