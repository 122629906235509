/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { FileImageOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import ImageShowcase from "../../image/imageShowcase";
export default function ImageUploader({
  multiple = false,
  value = [],
  onChange = () => {},
}) {
  let fileRef = useRef();

  let [files, setFiles] = useState(value);
  let [selectedImages, setSelectedImages] = useState([]);
  useEffect(() => {
    onChange(files);
  }, [files]);

  let fileChangeHandler = (e) => {
    setFiles(Array.from(e.target.files));
    setSelectedImages([]);
  };

  let removeSelectedImageHandler = () => {
    if (window.confirm("Do you want to procceed?")) {
      setFiles((prevState) =>
        prevState.filter((e, index) => !selectedImages.includes(index))
      );
      setSelectedImages([]);
    }
  };

  return (
    <div>
      <div
        className="border-dashed border-2 pa-4 rounded-lg text-center bg-white cursor-pointer hover:bg-sky-50/100"
        onClick={() => fileRef.current.click()}
      >
        <FileImageOutlined className="text-6xl text-emerald-500" />
        <div className="text-lg font-medium text-black">
          Drop your Image here, or <span className="text-sky-500">Browse</span>
          <input
            type="file"
            hidden
            ref={fileRef}
            accept="image/png, image/gif, image/jpeg"
            multiple={multiple}
            onChange={fileChangeHandler}
          />
        </div>
      </div>

      <Row className="mt-4">
        <Col md={24}>
          <ImageShowcase
            images={files}
            md={6}
            enableSelect={true}
            selectedImages={selectedImages}
            selectOnChange={(imagesIndexes) => setSelectedImages(imagesIndexes)}
            removeSelectedImages={removeSelectedImageHandler}
            // imgHeight={300}
          />
        </Col>
      </Row>
    </div>
  );
}
