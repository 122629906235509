import React from "react";
import { Select } from "antd";
import Label from "../label/label";
const { Option } = Select;

export default function SelectComponent({
  label = "",
  errorText = "",
  labelClass = "mb-2",
  labelStyle={},
  errotTextClass = "",
  errotTextStyle = {},
  items = [],
  itemText,
  itemValue,
  placeholder="",
  required = false,
  className="w-100",
  size="large",
  onChange=()=>{},
  ...args

}) {
  return <>{items && (
    <div >
      <Label label={label} className={`${labelClass} pl-1`} style={labelStyle} required={required} />
      <Select
        allowClear
        showSearch
        placeholder={placeholder ? placeholder : "Select"}
        optionFilterProp="children"
        onChange={onChange}
        filterOption={(input, option) =>
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        size={size}
        className={className}
        status={errorText?.length ? "error" : false}
        {...args}
      >
        {items.map((e, index) => (
          <Option
            value={
              itemValue?.length && e[itemValue]?.toString().length
                ? e[itemValue]
                : e.toString()
            }
            key={index}
            className="initial-whitespace-child"
          >
            {itemText?.length && e[itemText] ? e[itemText] : e.toString()}
          </Option>
        ))}
      </Select>
      <Label label={errorText} className={`${errotTextClass} pl-1 mt-1`} style={errotTextStyle} error />

    </div>
  )}</>;
}
