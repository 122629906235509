import { Col } from "antd";
import React, { useEffect, useState } from "react";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import Loader from "../../components/reusable/loader/loader";
import DataTable from "../../components/reusable/table/dataTable";
import {
  CREATE_INSTITUTE,
  DELETE_INSTITUTE,
  GET_ALL_DISTRICT,
  GET_ALL_INSTITUTE,
  GET_ALL_UPAZILA_BY_DISCTRICT_ID,
  UPDATE_INSTITUTE,
} from "../../config/api/api";
import useHTTP from "../../config/http/useHTTP";
import Save from "../../components/reusable/buttons/saveButton";
import { useNavigate } from "react-router-dom";
import { MEMBER } from "../../config/router/slug";

export default function Institute() {
  let [loading, setLoading] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);
  let [institutes, setInstitutes] = useState([]);
  let [districts, setDistricts] = useState([]);
  let [upazillas, setUpazillas] = useState([]);
  let [selectedDistrict, setSelectedDistrict] = useState(null);
  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();
  let [formData, setFormData] = useState({});

  let navigate = useNavigate()

  useEffect(() => {
    getAllDistrics();
    getInstitutes();
  }, []);
  useEffect(() => {
    if (selectedDistrict) {
      getAllUpazillaByDistrictId();
    }
  }, [selectedDistrict]);

  let getAllDistrics = async () => {
    setLoading(true);
    let res = await GetData({ api: GET_ALL_DISTRICT });
    if (res) {
      console.log(res.data);
      setDistricts(res.data);
    }
    setLoading(false);
  };
  let getInstitutes = async () => {
    setLoading(true);
    let res = await GetData({ api: GET_ALL_INSTITUTE });
    if (res) {
      console.log(res.data);
      setInstitutes(res.data);
    }
    setLoading(false);
  };
  let getAllUpazillaByDistrictId = async () => {
    setLoading(true);
    let res = await GetData({
      api: `${GET_ALL_UPAZILA_BY_DISCTRICT_ID}/${selectedDistrict}`,
    });
    if (res) {
      console.log(res.data);
      setUpazillas(res.data);
    }
    setLoading(false);
  };

  let onSubmitHandler = async (obj) => {
    setLoading(true);

    console.log(obj);
    if (updateMode) {
      // console.log(obj);
      let res = await PostData({ api: `${UPDATE_INSTITUTE}`, body: obj });
      if (res) {
        setInstitutes((prevState) =>
          prevState.map((e) => {
            if (e.id == obj.id) {
              e = { ...obj };
            }
            return e;
          })
        );
        reset();
      }
    } else {
      let res = await PostData({ api: CREATE_INSTITUTE, body: obj });
      if (res) {
        setInstitutes((prevState) => [...prevState, res.data]);
        reset();
      }
    }

    setLoading(false);
  };

  let onCancelHandler = async () => {
    reset();
  };
  let reset = () => {
    setFormData({});
    setUpdateMode(false);
  };

  let editHandler = async (obj) => {
    setFormData(obj);
    setUpdateMode(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  let deleteHandler = async (obj) => {
    if (window.confirm("Do you want to procceed?")) {
      setLoading(true);
      let res = await DeleteData({
        api: `${DELETE_INSTITUTE}/${obj.id}`,
        body: obj,
      });
      if (res) {
        setInstitutes((prevState) => prevState.filter((e) => e.id != obj.id));
      }
      setLoading(false);
    }
  };

  let onChangeObserveHandler = (obj) => {
    if (obj && obj.key === "districtId") {
      setSelectedDistrict(obj.value);
    }
  };

  let gotoMember = (obj)=>{
    console.log(obj)
    navigate(`${MEMBER}?identifier=${obj.identifier}`)
  }

  let formGeneratorData = [
    {
      type: "select",
      label: "District",
      key: "districtId",
      md: 24,
      // required: true,
      placeholder: "Select District",
      items: districts,
      itemText: "district",
      itemValue: "id",
      observeOnChange: true,
      required: true,
    },
    {
      type: "select",
      label: "Upazila",
      key: "upazilaId",
      md: 24,
      // required: true,
      placeholder: "Select Upazila",
      items: upazillas,
      itemText: "upazila",
      itemValue: "id",
      required: true,
    },
    {
      type: "input",
      label: "Name",
      key: "name",
      md: 24,
      required: true,
      placeholder: "Name",
    },
    {
      type: "input",
      label: "EIIN",
      key: "eiin",
      md: 24,
      placeholder: "EIIN",
      required: true,
    },
    {
      type: "input",
      label: "Identifier",
      key: "identifier",
      md: 24,
      placeholder: "Identifier",
      required: true,
    },
    {
      type: "input",
      label: "Phone",
      key: "phone",
      md: 24,
      placeholder: "Phone",
      required: true,
    },
  ];

  let headers = [
    { title: "Name", dataIndex: "name" },
    { title: "EIIN", dataIndex: "eiin" },
    { title: "Identifier", dataIndex: "identifier" },
    { title: "Phone", dataIndex: "phone" },
    { title: "Upazila", dataIndex: "upazila" },
    { title: "Created At", dataIndex: "createdAt" },
    { title: "", render:(cellValue, rowValue)=><Save btnName="Check Members" onClick={()=>gotoMember(rowValue)} /> },
    { title: "", type: "update" },
    { title: "", type: "delete" },
  ];

  return (
    <>
      {loading && <Loader />}

      <div className="card pa-4">
        <Col md={8}>
          <FormGenerator
            formData={formData}
            customLayout
            generatorData={formGeneratorData}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            updateMode={updateMode}
            onChangeObserver={onChangeObserveHandler}
          />
        </Col>
      </div>

      <div className="card pa-4 mt-4">
        <DataTable
          rowKey="id"
          columns={headers}
          dataSource={institutes}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
        />
      </div>
    </>
  );
}



let formRules = {
  name: { required: true },
  identifier: { required: true },
  eiin: { required: true },
  phone: { required: true, type: "phone" },
  upazilaId: { required: true },
};
