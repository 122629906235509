import { Col } from "antd";
import React, { useEffect, useState } from "react";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import Loader from "../../components/reusable/loader/loader";
import DataTable from "../../components/reusable/table/dataTable";
import {
  CREATE_ASSIGNMENT_TYPE,
  DELETE_ASSIGNMENT_TYPE,
  UPDATE_ASSIGNMENT_TYPE,
} from "../../config/api/api";
import useHTTP from "../../config/http/useHTTP";
import useAssignmentType from "../../hooks/useAssignmentType";

export default function AssignmentType() {
  let [loading, setLoading] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);
  let [assignmentTypes, setAssignmentTypes, assignmentTypeLoading] =
    useAssignmentType([]);
  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  let [formData, setFormData] = useState({});

  let onSubmitHandler = async (obj) => {
    setLoading(true);

    if (updateMode) {
      // console.log(obj);
      let res = await UpdateData({
        api: `${UPDATE_ASSIGNMENT_TYPE}`,
        body: obj,
      });
      if (res) {
        setAssignmentTypes((prevState) =>
          prevState.map((e) => {
            if (e.id == obj.id) {
              e = { ...obj };
            }
            return e;
          })
        );
        reset();
      }
    } else {
      let res = await PostData({ api: CREATE_ASSIGNMENT_TYPE, body: obj });
      if (res) {
        console.log([...assignmentTypes, res.data]);
        setAssignmentTypes([...assignmentTypes, res.data]);
        reset();
      }
    }

    setLoading(false);
  };
  let onCancelHandler = async () => {
    reset();
  };
  let reset = () => {
    setFormData({});
    setUpdateMode(false);
  };

  let editHandler = async (obj) => {
    setFormData(obj);
    setUpdateMode(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };
  let deleteHandler = async (obj) => {
    if (window.confirm("Do you want to procceed?")) {
      setLoading(true);
      let res = await DeleteData({
        api: `${DELETE_ASSIGNMENT_TYPE}/${obj.id}`,
        body: obj,
      });
      if (res) {
        setAssignmentTypes((prevState) =>
          prevState.filter((e) => e.id != obj.id)
        );
      }
      setLoading(false);
    }
  };

  return (
    <>
      {(assignmentTypeLoading || loading) && <Loader />}
      <div className="card pa-4">
        <Col md={8}>
          <FormGenerator
            formData={formData}
            customLayout
            generatorData={formGeneratorData}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            updateMode={updateMode}
          />
        </Col>
      </div>
      <div className="card pa-4 mt-4">
        <DataTable
          rowKey="id"
          columns={headers}
          dataSource={assignmentTypes}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
          // tableHeadline="AssignmentType"
        />
      </div>
    </>
  );
}

let headers = [
  { title: "Assignment Type", dataIndex: "assignmentType" },

  { title: "", type: "update" },
  { title: "", type: "delete" },
];

let formGeneratorData = [
  {
    type: "input",
    label: "Assignment Type ",
    key: "assignmentType",
    md: 24,
    required: true,
    placeholder: "Enter Assignment Type",
  },
];

let formRules = {
  assignmentType: { required: true },
};
