/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import Delete from "../buttons/deleteButton";
import Save from "../buttons/saveButton";
import ImageComponent from "./image";

export default function ImageShowcase({
  images,
  imgSrc,
  imgId,
  md,
  imgHeight,
  selectOnChange = () => {},
  removeSelectedImages = () => {},
  enableSelect,
  selectedImages = [],
}) {
  let [selectedImagesArray, setSelectedImagesArray] = useState(selectedImages);

  useEffect(() => {
    selectOnChange(selectedImagesArray);
  }, [selectedImagesArray]);
  useEffect(() => {
    setSelectedImagesArray(selectedImages);
  }, [selectedImages]);

  let onChangeHandler = (index) => {
    let arr = selectedImagesArray.filter((e) => e !== index);
    if (arr.length === selectedImagesArray.length) {
      arr.push(index);
    }
    setSelectedImagesArray(arr);
  };
  let selectAllHandler = (type) => {
    if (type === true) {
      let arr = images.map((e, index) => index);
      setSelectedImagesArray(arr);
    } else {
      setSelectedImagesArray([]);
    }
  };

  return (
    <div>
      {enableSelect && images.length > 0 && (
        <Col md={24} className="mb-4">
          <div className="pa-4 flex align-center bg-white rounded-sm">
            {/* Check box */}
            <Checkbox
              size="large"
              className="antd-checkbox-large"
              checked={images.length === selectedImagesArray.length}
              onChange={(e) => selectAllHandler(e.target.checked)}
            >
              <span className="text-base">
                {images.length} items. &nbsp;
                {images.length === selectedImagesArray.length
                  ? "Unselect All"
                  : "Select All"}
                .
              </span>
            </Checkbox>

            {/* Remove Selected Item Section */}
            {selectedImages.length > 0 && (
              <span className="text-blue-900 font-bold text-lg ml-40">
                {selectedImages.length} item selected
                <Delete
                  className="ml-4"
                  btnName="Remove"
                  onClick={removeSelectedImages}
                />
              </span>
            )}
          </div>
        </Col>
      )}
      {images?.length > 0 && (
        <Row className="bg-indigo-50 pa-2">
          {images.map((img, index) => (
            <Col md={md ? md : 6} key={index} className="pa-2">
              <div className="object-contain h-100 px-4 py-4 rounded-md  bg-white">
                {enableSelect && (
                  <div className="pb-2">
                    <Checkbox
                      onChange={() => onChangeHandler(index)}
                      checked={selectedImagesArray.includes(index)}
                    />
                  </div>
                )}
                <ImageComponent
                  height={imgHeight}
                  className="object-contain"
                  src={imgSrc ? img[imgSrc] : img}
                  alt="file"
                />
              </div>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}
