import { Col } from "antd";
import React, { useEffect, useState } from "react";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import Loader from "../../components/reusable/loader/loader";
import DataTable from "../../components/reusable/table/dataTable";
import {
  CREATE_FEEDBACK_FOR_PARTNER,
  DELETE_FEEDBACK_FOR_PARTNER,
  GET_ALL_FEEDBACK_FOR_PARTNER,
  UPDATE_FEEDBACK_FOR_PARTNER,
} from "../../config/api/api";
import useHTTP from "../../config/http/useHTTP";
import usePartner from "../../hooks/usePartner";

export default function FeedbackForPartner() {
  let [loading, setLoading] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);
  let [feedbackForPartners, setFeedbackForPartners, areaLoading] = useState([]);
  let [partners, setPartners, partnerLoading] = usePartner([]);
  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  let [formData, setFormData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  let getData = async (obj) => {
    setLoading(true);
    let res = await GetData({ api: GET_ALL_FEEDBACK_FOR_PARTNER });
    if (res.data) {
      setFeedbackForPartners(res.data);
    }
    setLoading(false);
  };

  let onSubmitHandler = async (obj) => {
    setLoading(true);

    if (updateMode) {
      // console.log(obj);
      let res = await UpdateData({
        api: `${UPDATE_FEEDBACK_FOR_PARTNER}`,
        body: obj,
      });
      if (res) {
        setFeedbackForPartners((prevState) =>
          prevState.map((e) => {
            if (e.id == obj.id) {
              e = { ...obj };
            }
            return e;
          })
        );
        reset();
      }
    } else {
      let res = await PostData({ api: CREATE_FEEDBACK_FOR_PARTNER, body: obj });
      if (res) {
        setFeedbackForPartners((prevState) => [...prevState, res.data]);
        reset();
      }
    }

    setLoading(false);
  };
  let onCancelHandler = async () => {
    reset();
  };
  let reset = () => {
    setFormData({});
    setUpdateMode(false);
  };

  let editHandler = async (obj) => {
    setFormData(obj);
    setUpdateMode(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };
  let deleteHandler = async (obj) => {
    if (window.confirm("Do you want to procceed?")) {
      setLoading(true);
      let res = await DeleteData({
        api: `${DELETE_FEEDBACK_FOR_PARTNER}/${obj.id}`,
        body: obj,
      });
      if (res) {
        setFeedbackForPartners((prevState) =>
          prevState.filter((e) => e.id != obj.id)
        );
      }
      setLoading(false);
    }
  };

  let formGeneratorData = [
    {
      type: "select",
      label: "Partner",
      key: "partnerId",
      md: 24,
      // required: true,
      placeholder: "Select Partner",
      items: partners,
      itemText: "name",
      itemValue: "id",
    },
    {
      type: "input",
      fieldType: "textarea",
      label: "Note",
      key: "note",
      md: 24,
      required: true,
      placeholder: "Note",
    },
    // {
    //   type: "textEditor",
    //   label: "Area Description",
    //   key: "areaDescription",
    //   md: 24,
    // },
  ];

  return (
    <>
      {(areaLoading || loading) && <Loader />}
      <div className="card pa-4">
        <Col md={8}>
          <FormGenerator
            formData={formData}
            customLayout
            generatorData={formGeneratorData}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            updateMode={updateMode}
          />
        </Col>
      </div>
      <div className="card pa-4 mt-4">
        <DataTable
          rowKey="id"
          columns={headers}
          dataSource={feedbackForPartners}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
        />
      </div>
    </>
  );
}

let headers = [
  { title: "Note", dataIndex: "note" },
  { title: "PartnerID", dataIndex: "partnerId" },
  { title: "", type: "update" },
  { title: "", type: "delete" },
];

let formRules = {
  partnerId: { required: true },
  note: { required: true },
};
