/*
  author: hasib
*/
import { DatePicker } from "antd";
import dayjs from "dayjs";
import Label from "../label/label";
export default function DatePickerComponent({
  onChange,
  label,
  labelClass = "mb-2",
  labelStyle,
  errorText,
  errotTextClass = "",
  value,
  required,
  size = "large",
  ...args
}) {
  return (
    <>
      <Label
        label={label}
        className={`${labelClass} pl-1`}
        labelStyle={labelStyle}
        required={required}
      />
      <DatePicker
        className="w-100"
        size={size}
        onChange={(value) =>
          onChange(value, dayjs(value.$d).format("YYYY-MM-DD"))
        }
        status={errorText?.length ? "error" : false}
        value={value ? dayjs(value) : undefined}
        format={"D-MMM-YYYY"}
        {...args}
      />
      <Label
        label={errorText}
        error
        className={`${errotTextClass} pl-1 mt-1`}
      />
    </>
  );
}
