import Sidebar from "./components/layout/sidebar/sidebar";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import routes from "./config/router/routes";
import { HOME, LOGIN } from "./config/router/slug";
import Login from "./pages/login/login.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Delete from "./components/reusable/buttons/deleteButton";
import Save from "./components/reusable/buttons/saveButton";

function App() {
  return (
    <>
      <Routes>
        <Route path={LOGIN} element={<Login />} />

        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={<Layout>{route.element}</Layout>}
          />
        ))}

        {/* If link is not exists, Then redirect to home page */}
        <Route path="*" element={<Navigate replace to={HOME} />} />
      </Routes>
    </>
  );
}

function Layout({ children }) {
  let collapse = () => {};
  return (
    <div style={{ height: "calc(100vh )", overflowY: "scroll" }}>
      <div className="header">
        <Save btnName="Collapse" onClick={collapse} />
        Sohokormi
        <Delete
          btnName="Log Out"
          onClick={() => {
            localStorage.removeItem("token");
            window.location.href = "/login";
          }}
        />
      </div>
      {/* Body */}
      <div
        style={{
          display: "flex",
          marginTop: "100px",
          // border: "10px solid red",
          // minHeight: "calc(100vh-100px)",
        }}
      >
        <div className="sidebar">
          <Sidebar />
        </div>

        <div style={{ padding: "20px" }} className="content">
          {children}
        </div>
      </div>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
