import {
  HomeOutlined,
  MinusOutlined,
  SettingOutlined,
  ProfileOutlined,
  FileProtectOutlined,
  UserOutlined,
} from "@ant-design/icons";
import * as slug from "../../../config/router/slug";

const menuCollections = [
  // {
  //   name: "Home",
  //   icon: <HomeOutlined />,
  //   to: slug.HOME,
  // },
  {
    name: "Settings",
    icon: <SettingOutlined />,
    childrens: [
      
      {
        name: "Department",
        icon: <HomeOutlined />,
        to: slug.DEPARTMENT,
      },
      {
        name: "Designation",
        icon: <HomeOutlined />,
        to: slug.DESIGNATION,
      },
      {
        name: "Institute",
        icon: <HomeOutlined />,
        to: slug.INSTITUTE,
      },
      {
        name: "Member",
        icon: <HomeOutlined />,
        to: slug.MEMBER,
      },
      {
        name: "About",
        icon: <HomeOutlined />,
        to: slug.ABOUT,
      },
    ],
  },
  // {
  //   name: "Config",
  //   icon: <SettingOutlined />,
  //   childrens: [
  //     {
  //       name: "Zone",
  //       icon: <FileProtectOutlined />,
  //       to: slug.ZONE,
  //     },
  //     {
  //       name: "Area",
  //       icon: <FileProtectOutlined />,
  //       to: slug.AREA,
  //     },
  //     {
  //       name: "Assignment Type",
  //       icon: <FileProtectOutlined />,
  //       to: slug.ASSIGNMENT_TYPE,
  //     },
  //     {
  //       name: "Asset Type",
  //       icon: <FileProtectOutlined />,
  //       to: slug.ASSET_TYPE,
  //     },
  //     {
  //       name: "Asset Status",
  //       icon: <FileProtectOutlined />,
  //       to: slug.ASSET_STATUS,
  //     },
  //   ],
  // },
  // {
  //   name: "Partners",
  //   icon: <UserOutlined />,
  //   childrens: [
  //     {
  //       name: "User",
  //       icon: <UserOutlined />,
  //       to: slug.USER,
  //     },
  //     {
  //       name: "Partner",
  //       icon: <UserOutlined />,
  //       to: slug.PARTNER,
  //     },
  //   ],
  // },
  // {
  //   name: "Asset",
  //   icon: <UserOutlined />,
  //   childrens: [
  //     {
  //       name: "Asset",
  //       icon: <UserOutlined />,
  //       to: slug.ASSET,
  //     },
  //     {
  //       name: "Asset Profile",
  //       icon: <UserOutlined />,
  //       to: slug.ASSET_PROFILE,
  //     },
  //   ],
  // },
  // {
  //   name: "Feedback",
  //   icon: <UserOutlined />,
  //   childrens: [
  //     {
  //       name: "Feedback for Asset",
  //       icon: <UserOutlined />,
  //       to: slug.FEEDBACK_FOR_ASSET,
  //     },
  //     {
  //       name: "Feedback for Partner",
  //       icon: <UserOutlined />,
  //       to: slug.FEEDBACK_FOR_PARTNER,
  //     },
  //   ],
  // },
  // {
  //   name: "Login",
  //   icon: <SettingOutlined />,
  //   href: "https://google.com",
  //   // to: slug.LOGIN,
  // },
  //   {
  //     name: "Parameter Test",
  //     to: slug.TEST_PARAM_FUNC("para_one", "para_two"),
  //   },
];

export default menuCollections;
