import { Col } from "antd";
import React, { useEffect, useState } from "react";
import FormGenerator from "../../components/reusable/form/formGenerator/formGenerator";
import Loader from "../../components/reusable/loader/loader";
import DataTable from "../../components/reusable/table/dataTable";
import {
  CREATE_ASSET,
  DELETE_ASSET,
  GET_ALL_ASSET,
  UPDATE_ASSET,
} from "../../config/api/api";
import useHTTP from "../../config/http/useHTTP";
import usePartner from "../../hooks/usePartner";
import useArea from "../../hooks/useAreas";
import useAsset from "../../hooks/useAsset";
import useAssetType from "../../hooks/useAssetType";
import useAssetStatus from "../../hooks/useAssetStatus";

export default function Asset() {
  let [loading, setLoading] = useState(false);
  let [updateMode, setUpdateMode] = useState(false);

  let [assets, setAssets, assetsLoading] = useAsset([]);
  let [assetTypes, setAssetTypes, assetTypeLoading] = useAssetType([]);
  let [assetStatuses, setAssetStatus, assetStatusLoading] = useAssetStatus([]);
  let [areas, setAreas, areaLoading] = useArea([]);
  let [partners, setPartners, partnerLoading] = usePartner([]);

  let { GetData, PostData, UpdateData, DeleteData } = useHTTP();

  let [formData, setFormData] = useState({});

  let onSubmitHandler = async (obj) => {
    setLoading(true);

    if (updateMode) {
      // console.log(obj);
      let res = await UpdateData({ api: `${UPDATE_ASSET}`, body: obj });
      if (res) {
        setAssets((prevState) =>
          prevState.map((e) => {
            if (e.id == obj.id) {
              e = { ...obj };
            }
            return e;
          })
        );
        reset();
      }
    } else {
      let res = await PostData({ api: CREATE_ASSET, body: obj });
      if (res) {
        setAssets((prevState) => [...prevState, res.data]);
        reset();
      }
    }

    setLoading(false);
  };

  let onCancelHandler = async () => {
    reset();
  };
  let reset = () => {
    setFormData({});
    setUpdateMode(false);
  };

  let editHandler = async (obj) => {
    setFormData(obj);
    setUpdateMode(true);
    window.scroll({ top: 0, behavior: "smooth" });
  };

  let deleteHandler = async (obj) => {
    if (window.confirm("Do you want to procceed?")) {
      setLoading(true);
      let res = await DeleteData({
        api: `${DELETE_ASSET}/${obj.id}`,
        body: obj,
      });
      if (res) {
        setAssets((prevState) => prevState.filter((e) => e.id != obj.id));
      }
      setLoading(false);
    }
  };

  return (
    <>
      {(assetsLoading ||
        assetTypeLoading ||
        assetStatusLoading ||
        areaLoading ||
        partnerLoading ||
        loading) && <Loader />}
      <div className="card pa-4">
        <Col md={8}>
          <FormGenerator
            formData={formData}
            customLayout
            generatorData={formGeneratorData(
              partners,
              areas,
              assetTypes,
              assetStatuses
            )}
            formRules={formRules}
            onSubmit={onSubmitHandler}
            onCancel={onCancelHandler}
            updateMode={updateMode}
          />
        </Col>
      </div>
      <div className="card pa-4 mt-4">
        <DataTable
          rowKey="id"
          columns={headers}
          dataSource={assets}
          editHandler={editHandler}
          deleteHandler={deleteHandler}
        />
      </div>
    </>
  );
}

let headers = [
  { title: "Asset Name", dataIndex: "assetName" },
  { title: "EIIN", dataIndex: "eiin" },
  { title: "Dcampus ID", dataIndex: "dcampusId" },
  { title: "Area", dataIndex: "areaName" },
  { title: "Partner Name", dataIndex: "partnerName" },
  { title: "Partner Phone", dataIndex: "partnerPhone" },
  { title: "Asset Type", dataIndex: "assetType" },
  { title: "Asset Status", dataIndex: "assetStatus" },
  { title: "", type: "update" },
  { title: "", type: "delete" },
];

let formGeneratorData = (partners, areas, assetTypes, assetStatuses) => {
  return [
    {
      type: "select",
      label: "Partner",
      key: "partnerId",
      md: 24,
      // required: true,
      placeholder: "Select Partner",
      items: partners,
      itemText: "name",
      itemValue: "id",
    },
    {
      type: "select",
      label: "Area",
      key: "areaId",
      md: 24,
      required: true,
      placeholder: "Select Zone",
      items: areas,
      itemText: "areaName",
      itemValue: "id",
    },
    {
      type: "input",
      label: "Asset Name",
      key: "assetName",
      md: 24,
      required: true,
      placeholder: "Asset Name",
    },
    {
      type: "input",
      label: "EIIN",
      key: "eiin",
      md: 24,
      placeholder: "EIIN",
    },
    {
      type: "select",
      label: "Asset Type",
      key: "assetTypeId",
      md: 24,
      placeholder: "Asset Type",
      items: assetTypes,
      itemText: "assetType",
      itemValue: "id",
    },
    {
      type: "select",
      label: "Asset Status",
      key: "assetStatusId",
      md: 24,
      placeholder: "Asset Status",
      items: assetStatuses,
      itemText: "assetStatus",
      itemValue: "id",
    },
    // {
    //   type: "textEditor",
    //   label: "Area Description",
    //   key: "areaDescription",
    //   md: 24,
    // },
  ];
};

let formRules = {
  assetName: { required: true },
  areaId: { required: true },
  areaDescription: { maxLen: 1000 },
};
